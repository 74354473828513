import { createReducer, createActions } from 'reduxsauce'
import { storage } from '@utils'

export const INITIAL_STATE = {}

const {Types, Creators} = createActions({
  loginUser: ['data'],
  updateUser: ['callback'],
  updateUserStore: ['data'],
  logoutUser: null,
  getUserProfile: ['data']
})

const loginUser = (state, { data }) => ({ ...state, ...data })
const updateUser = (state, { data }) => ({ ...state, ...data })
const getUserProfile = (state, { data }) => ({ ...state, ...data })

const logoutUser = () => {
  console.log('reducer logoutuser')
  storage.clear('mozrestApp:token')
  storage.clear('mozrestApp:refreshtoken')
  storage.clear('persist:mozrestApp')
  storage.clear('mozrestApp:userData')
  storage.clear('mozrestApp:companyType')
  storage.clear('mozrestApp:totalVenues')
  return {}
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOGIN_USER]: loginUser,
  [Types.LOGOUT_USER]: logoutUser,
  [Types.UPDATE_USER]: null,
  [Types.UPDATE_USER_STORE]: updateUser,
  [Types.GET_USER_PROFILE]: getUserProfile
})

export const UserTypes = Types
export default Creators
