import withReactContent from "sweetalert2-react-content"
import {useIntl} from 'react-intl'
import Swal from "sweetalert2"

const MySwal = withReactContent(Swal)

export const modalConfirmation = ({
                                      title,
                                      text,
                                      icon,
                                      confirmButton,
                                      cancelButton,
                                      funtionToExecute,
                                      errorMessage,
                                      onSuccess
                                  }) => {
    const intl = useIntl()

    return MySwal.fire({
        title: title || intl.formatMessage({id: 'Are you sure?'}),
        text,
        icon,
        showCancelButton: true,
        confirmButtonText: confirmButton || intl.formatMessage({id: 'Confirm'}),
        cancelButtonText: cancelButton || intl.formatMessage({id: 'Cancel'}),
        customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
    }).then(async (result) => {
        if (result.value) {
            const [, response] = await handlePromise(
                funtionToExecute()
            )
            if (!response.ok) {
                MySwal.fire({
                    icon: 'error',
                    title: intl.formatMessage({id: 'Error'}),
                    text: errorMessage || intl.formatMessage({id: 'There was an error. Please contact the administrator.'}),
                    customClass: {
                        confirmButton: 'btn btn-error'
                    }
                })
            } else {
                onSuccess()
            }
        }
    })

}
