import { countries } from '@configs/Constants'
import Geocode from "react-geocode"
import { storage } from '@utils'
import _ from "lodash"

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
 export const getUserData = () => JSON.parse(localStorage.getItem('persist:mozrestApp'))
 export const isUserLoggedIn = () => {
  const user = getUserData()
  return localStorage.getItem('mozrestApp:token') && !!user && user.user.length > 0
}

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (_.indexOf(userRole, 'ROLE_ADMIN') || _.indexOf(userRole, 'ROLE_MANAGER') || _.indexOf(userRole, 'ROLE_VENUE') || _.indexOf(userRole, 'ROLE_RESERVATION_PORTAL')) return '/'
  if (userRole === 'client') return '/access-control'
  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const renderCountry = country => {
  return countries[_.findIndex(countries, ['value', country])].label
}

export const getUserCompany = () => {
  const userData = JSON.parse(storage.get('mozrestApp:userData'))
  return userData.company.id
}
export const getUserCompanyRms = () => {
  //TO FIX
  const userData = JSON.parse(storage.get('mozrestApp:userData'))
  return userData.currentRms || '619dd1c5b406dfc2f967a982'
}

export const getUserRoles = () => {
  const userData = JSON.parse(storage.get('mozrestApp:userData'))
  return userData.roles
}

export const isBookingChannel = () => {
  return _.indexOf(getUserRoles(), 'ROLE_RESERVATION_PORTAL') >= 0
}

export const isSuperAdmin = () => {
  return _.indexOf(getUserRoles(), 'ROLE_SUPER_ADMIN') >= 0
}

export const totalVenues = () => {
  return localStorage.getItem('mozrestApp:totalVenues')
}

export const venueType = (type = 'many') => {
  const companyType = localStorage.getItem('mozrestApp:companyType')
  if (companyType === 'restaurant' && type === 'one') return 'restaurant'
  if (companyType === 'restaurant' && type === 'many') return 'restaurants'
  if (companyType === 'garage' && type === 'one') return 'garage'
  if (companyType === 'garage' && type === 'many') return 'garages'
  return 'restaurants'
}

export const bcStatsColor = (rp) => {
  if (rp === 'RWG') return '#2b7de9'
  if (rp === 'FB') return '#4267B2'
  if (rp === 'IG') return '#E1306C'
  if (rp === 'PERE') return '#E8A014'
  if (rp === 'MG') return '#bd2333'
  if (rp === 'TOL') return '#111111'
  if (rp === 'GON') return '#1DB7CE'
  if (rp === 'FORM') return '#006bff'
  if (rp === 'E2E') return '#f9ab27'
  if (rp === 'ZONAL') return '#28A4A0'

  return '#1f155a'
}

export const srcCode = (src) => {
  if (src === 'RWG') return 'RWG'
  if (src === 'FB') return 'FB'
  if (src === 'IG') return 'IG'
  if (src === 'PERE') return 'PERE'
  if (src === 'MG') return 'MG'
  if (src === 'TOL') return 'TOL'
  if (src === 'GON') return 'GOL'
  if (src === 'FORM') return 'FOR'
  if (src === 'E2E') return 'E2E'
  if (src === 'ZONAL') return 'ZONAL'

  return '#1f155a'
}

export const getLtnLngFromAddress = async (address) => {
  Geocode.setApiKey(process.env.REACT_APP_MAPS_API_KEY)
  let latitude = 0
  let longitude = 0
  if (address) {
    await Geocode.fromAddress(address).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location
          latitude = lat
          longitude = lng
        },
        (error) => {
          console.error(error)
        }
    )

    return ({ latitude, longitude })
  }
}
