import { applyMiddleware, compose, createStore } from 'redux'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import { persistStore, persistReducer, createTransform } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import { createStateSyncMiddleware, initStateWithPrevTab } from 'redux-state-sync'

import omit from 'lodash/omit'
import reducers from './reducers'
import sagas from './saga'
import { composeWithDevTools } from 'redux-devtools-extension'
import { INITIAL_STATE as layoutInitialState } from './reducers/Layout'

const history = createBrowserHistory()
const routeMiddleware = routerMiddleware(history)
const sagaMiddleware = createSagaMiddleware()
const config = {
    // Only 'TOGGLE_TODO' will be triggered in other tabs
    whitelist: ['FB_CONNECTED']
}
const middlewares = [routeMiddleware, sagaMiddleware, createStateSyncMiddleware(config)]

const blacklistTransform = createTransform(
  (inboundState, key) => {
    if (key === 'layout') {
      //return omit( inboundState, ['menuCollapsed', 'menuHidden', 'contentWidth'] )
    }
    if (key === 'user') {
      return omit(inboundState, ['profile'])
    }
    return inboundState
  },
  (outboundState, key) => {
    if (key === 'layout') {
      return { ...layoutInitialState, ...outboundState }
    }
    return outboundState
  }
)

const persistConfig = {
  key: 'mozrest',
  whitelist: ['user', 'layout'],
  storage,
  transforms: [blacklistTransform]
}
const persistedReducer = persistReducer(persistConfig, reducers(history))

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
)
// init state with other tabs
initStateWithPrevTab(store)
// initMessageListener(store);
sagaMiddleware.run(sagas)

const persistor = persistStore(store)

export default store
export { history, persistor, store }
