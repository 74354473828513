import api from '@configs/Api/Api'

export default {

  loginUser(data) {
    return api.post('/v2/token', {
      ...data,
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      grant_type: 'password',
      scope: null
    })
  },
  refreshToken(refresh_token) {
    return api.post('/v2/token', {
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_CLIENT_SECRET,
      grant_type: 'refresh_token',
      refresh_token
    })
  }
}
